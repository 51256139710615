import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInterceptor';
import { toast } from 'react-toastify';

const ChangePasssword = () => {
    const navigator = useNavigate();
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setErrors({ ...errors, [name]: '' });
        if (name === 'oldPassword') {
            setOldPassword(value);
        } else if (name === 'newPassword') {
            setNewPassword(value);
        } else if (name === 'confirmPassword') {
            setConfirmPassword(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let errors = {};

        if (!oldPassword.trim()) {
            errors.oldPassword = 'Old password is required';
        }
        if (!newPassword.trim()) {
            errors.newPassword = 'New password is required';
        }
        if (!newPassword.trim()) {
            errors.confirmPassword = 'Confirm Password is required';
        } else if (newPassword !== confirmPassword) {
            errors.confirmPassword = "Passwords don't match";
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }

        try {
            const response = await axiosInstance.post(
                `admin/changePassword`,
                {
                    oldPassword: oldPassword,
                    newPassword: newPassword
                }
            );
            if (response.status === 200) {
                toast.success(response.data.message)
                navigator("/")
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    }
    return (
        <>
            <div className="content-wrapper">
                <div className="page-header mt-5 mx-4">
                    <h3 className="page-title">Change Password </h3>
                </div>
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="col-md-8  ">
                        <div className="card">
                            <div className="card-body">
                                <form className="pt-3" onSubmit={handleSubmit}>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Old Password
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="password"
                                                className="form-control form-control-sm"
                                                id="oldPassword"
                                                name="oldPassword"
                                                placeholder="Enter old password"
                                                value={oldPassword}
                                                onChange={handleChange}
                                            />
                                            {errors.oldPassword && <p className="text-danger">{errors.oldPassword}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            New Password
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="password"
                                                className="form-control form-control-sm"
                                                id="newPassword"
                                                name="newPassword"
                                                placeholder="Enter new password"
                                                value={newPassword}
                                                onChange={handleChange}
                                            />
                                            {errors.newPassword && <p className="text-danger">{errors.newPassword}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Confirm Password
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="password"
                                                className="form-control form-control-sm"
                                                id="confirmPassword"
                                                name="confirmPassword"
                                                placeholder="Enter confirm password"
                                                value={confirmPassword}
                                                onChange={handleChange}
                                            />
                                            {errors.confirmPassword && <p className="text-danger">{errors.confirmPassword}</p>}
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        <Link to="/dashboard"><button className="btn btn-secondary mr-2">
                                            Back </button></Link>
                                        <button type="submit" className="btn btn-success">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChangePasssword