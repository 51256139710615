import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInterceptor';
import { toast } from 'react-toastify';

const Forgotpassword = () => {
    const [submitAttempted, setSubmitAttempted] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false)

    const navigator = useNavigate();
    const [data, setData] = useState({ email: '', otpCode: '' });
    const [errors, setErrors] = useState({});
    const [forgetData, setForgetData] = useState([])
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitAttempted(true)
        let hasErrors = false;
        const { email } = data;
        if (!email.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: 'Email is required'
            }));
            hasErrors = true;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: 'Please enter a valid email address'
            }));
            hasErrors = true;
        }

        if (hasErrors) {
            return;
        }

        try {
            const response = await axiosInstance.post(`admin/forgotPassword`, { email: email });
            setForgetData(response.data)
            if (response.status === 200) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    const otpVerify = async (e) => {
        e.preventDefault();
        setFormSubmitted(true)
        let hasErrors = false;

        if (!data.email.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: 'Email is required'
            }));
            hasErrors = true;
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: 'Please enter a valid email address'
            }));
            hasErrors = true;
        }
        if (!data.otpCode.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                otpCode: 'Otp code is required'
            }));
            hasErrors = true;
        }
        if (hasErrors) {
            return;
        }

        try {
            const response = await axiosInstance.post(`admin/verifyOtp`, data);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/reset-password", { state: data })
            } else {
                toast.error(response.data.message);
                navigator("/");
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }

    }
    return (
        <>
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper full-page-wrapper">
                    <div className="content-wrapper d-flex align-items-center auth">
                        <div className="row flex-grow">
                            <div className="col-lg-4 mx-auto">
                                <div className="auth-form-light text-left p-5">
                                    <div className="brand-logo text-center">
                                        <img src="../../images/logo.png" alt="Logo_img" />
                                    </div>
                                    {forgetData.message === 'Otp is sent in your email.' ? "" : <>
                                        <h4 className='text-center'>Forgot Password</h4>
                                        <form className="pt-3" onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    className="form-control form-control-sm"
                                                    id="email"
                                                    name="email"
                                                    placeholder="Email"
                                                    value={data.email}
                                                    onChange={handleChange}
                                                />
                                                {submitAttempted && errors.email && <p className="text-danger">{errors.email}</p>}
                                            </div>

                                            <div className="mt-3">
                                                <button
                                                    type="submit"
                                                    className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                                                >
                                                    SEND
                                                </button>
                                            </div>
                                        </form>
                                    </>}
                                    {forgetData.message === 'Otp is sent in your email.' ?
                                        <> <h4 className='text-center'>Reset Password</h4>
                                            <form className="pt-3" onSubmit={otpVerify}>
                                                <div className="form-group">
                                                    <input
                                                        type="email"
                                                        className="form-control form-control-sm"
                                                        id="email"
                                                        name="email"
                                                        placeholder="Email"
                                                        value={data.email}
                                                        onChange={handleChange}
                                                    />
                                                    {formSubmitted && errors.email && <p className="text-danger">{errors.email}</p>}
                                                </div>

                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        id="otpCode"
                                                        name="otpCode"
                                                        placeholder="Enter otp"
                                                        value={data.otpCode}
                                                        onChange={handleChange}
                                                    />
                                                    {formSubmitted && errors.otpCode && <p className="text-danger">{errors.otpCode}</p>}
                                                </div>
                                                <div className="mt-3">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn text-uppercase"
                                                    >
                                                        Reset password
                                                    </button>
                                                </div>
                                            </form>
                                        </> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Forgotpassword