import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Contactview = () => {
    const location = useLocation();
    const [contactData, setContactData] = useState({
        name: '',
        email: '',
        message: '',
        phoneNumber: ''
    });

    useEffect(() => {
        if (location.state) {
            const { name, email, message, phoneNumber } = location.state;
            setContactData({ name, email, message, phoneNumber });
        }
    }, [location.state]);

    return (
        <div className="content-wrapper">
            <div className="page-header mt-5 mx-4">
                <h3 className="page-title">Contact Details </h3>
            </div>
            <div className="col-md-12 grid-margin stretch-card">
                <div className="col-md-8  ">
                    <div className="card">
                        <div className="card-body">
                            <form className="pt-3">
                                <div className="form-group row">
                                    <label
                                        htmlFor="exampleInputUsername2"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Name
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            id="name"
                                            name="name"
                                            placeholder="Enter name"
                                            value={contactData.name}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label
                                        htmlFor="exampleInputUsername2"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Email
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="email"
                                            className="form-control form-control-sm"
                                            id="email"
                                            name="email"
                                            placeholder="Enter email"
                                            value={contactData.email}
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label
                                        htmlFor="exampleInputUsername2"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Message
                                    </label>
                                    <div className="col-sm-9">
                                        <textarea
                                            className="form-control form-control-sm"
                                            id="message"
                                            name="message"
                                            placeholder="Enter message"
                                            value={contactData.message}
                                            disabled
                                            style={{ height: '150px' }}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label
                                        htmlFor="exampleInputUsername2"
                                        className="col-sm-3 col-form-label"
                                    >
                                        Phone Number
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            placeholder="Enter phone number"
                                            value={contactData.phoneNumber}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contactview;
