import React from 'react'

const Dashboard = () => {
    return (
        <>
            <div className="content-wrapper">              
              
            </div>
        </>
    )
}

export default Dashboard