import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../axiosInterceptor'
import { toast } from 'react-toastify'

const AddMenu = () => {
    const navigator = useNavigate();
    const location = useLocation();
    const editData = location.state
    const [data, setData] = useState({
        menuName: editData?.menuName || '',
        menuUrl: editData?.menuUrl || '',
        position: editData?.position || '',
        pageId: editData?.pageId?._id || null,
        metaTitle: editData?.metaTitle || '',
        metaDescription: editData?.metaDescription || '',
        metaKeyword: editData?.metaKeyword || '',
        isActive: editData?.isActive || false,
        showInHeader: editData?.showInHeader || false,
        showInFooter: editData?.showInFooter || false
    });

    const [errors, setErrors] = useState({
        position: '',
        menuName: '',
        menuUrl: '',
        metaTitle: '',
        metaDescription: '',
        metaKeyword: '',
    });

    const [pageData, setPageData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(`page/getWithoutPaginationPage`,);
            const { data } = response.data;
            setPageData(data);
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleChange = (e) => {
        const { name, checked, value } = e.target;
        if (name === "isActive" || name === "showInFooter" || name === "showInHeader") {
            setData({ ...data, [name]: checked });
        } else {
            setData({ ...data, [name]: value });
        }
        setErrors({ ...errors, [name]: '' });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasErrors = false;

        if (!data.position.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                position: 'Position is required'
            }));
            hasErrors = true;
        }
        if (!data.menuName) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                menuName: 'Menu name is required'
            }));
            hasErrors = true;
        }
        if (!data.menuUrl) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                menuUrl: 'Menu url is required'
            }));
            hasErrors = true;
        }
        if (!data.menu) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                menuName: 'Menu name is required'
            }));
            hasErrors = true;
        }
        if (hasErrors) {
            return;
        }
        try {
            const response = await axiosInstance.post(`menu/createMenu`, data);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/menu");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };
    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.put(`menu/updateMenu/${editData?._id}`, data);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/menu");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="page-header mt-5 mx-4">
                    <h3 className="page-title">Add Menu </h3>
                </div>
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="col-md-8  ">
                        <div className="card">
                            <div className="card-body">
                                <form className="pt-3" onSubmit={editData?._id ? handleUpdate : handleSubmit}>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            PageId
                                        </label>
                                        <div className="col-sm-9">
                                            <select type="text"
                                                className="form-control form-control-sm"
                                                id="pageId"
                                                name="pageId"
                                                placeholder="Select page id"
                                                onChange={handleChange}
                                                value={data.pageId}
                                            >
                                                <option value='-'>--- Root content ---</option>
                                                {
                                                    pageData.map((item, id) => {
                                                        return (
                                                            <option key={id} value={item._id}>{item.title}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Position
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                className="form-control form-control-sm"
                                                id="position"
                                                name="position"
                                                placeholder="Enter position"
                                                value={data.position}
                                                onChange={handleChange}
                                            />
                                            {errors.position && <p className="text-danger">{errors.position}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Menu Name
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="menuName"
                                                name="menuName"
                                                placeholder="Enter menu name"
                                                value={data.menuName}
                                                onChange={handleChange}
                                            />
                                            {errors.menuName && <p className="text-danger">{errors.menuName}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Menu Url
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="menuUrl"
                                                name="menuUrl"
                                                placeholder="Enter menu url"
                                                value={data.menuUrl}
                                                onChange={handleChange}
                                            />
                                            {errors.menuUrl && <p className="text-danger">{errors.menuUrl}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Meta Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="metaTitle"
                                                name="metaTitle"
                                                placeholder="Enter meta title"
                                                value={data.metaTitle}
                                                onChange={handleChange}
                                            />
                                            {errors.metaTitle && <p className="text-danger">{errors.metaTitle}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Meta Keyword
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="metaKeyword"
                                                name="metaKeyword"
                                                placeholder="Enter meta keyword"
                                                value={data.metaKeyword}
                                                onChange={handleChange}
                                            />
                                            {errors.metaKeyword && <p className="text-danger">{errors.metaKeyword}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Meta Description
                                        </label>
                                        <div className="col-sm-9">
                                            <textarea
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="metaDescription"
                                                name="metaDescription"
                                                placeholder="Enter meta description"
                                                value={data.metaDescription}
                                                onChange={handleChange}
                                            />
                                            {errors.metaDescription && <p className="text-danger">{errors.metaDescription}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 "
                                        > Is Active
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox" className="custom-control-input"
                                                    name='isActive'
                                                    id="isActiveSwitch"
                                                    checked={data.isActive}
                                                    onChange={(e) => handleChange(e)} />
                                                <label className="custom-control-label " for="isActiveSwitch">{data.isActive === true ? "Active" : "Deactive"}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="showInHeader"
                                            className="col-sm-3 "
                                        > Show In Header
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox" className="custom-control-input"
                                                    name='showInHeader'
                                                    id="showInHeader"
                                                    checked={data.showInHeader}
                                                    onChange={(e) => handleChange(e)} />
                                                <label className="custom-control-label " for="showInHeader">{data.showInHeader === true ? "Active" : "Deactive"}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="showInFooter"
                                            className="col-sm-3 "
                                        > Show In Footer
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox" className="custom-control-input"
                                                    name='showInFooter'
                                                    id="showInFooter"
                                                    checked={data.showInFooter}
                                                    onChange={(e) => handleChange(e)} />
                                                <label className="custom-control-label " for="showInFooter">{data.showInFooter === true ? "Active" : "Deactive"}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        <Link to="/menu"><button className="btn btn-secondary mr-2">
                                            Back </button></Link>
                                        <button type="submit" className="btn btn-success">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddMenu;