import axios from "axios";

const apiKey = process.env.REACT_APP_API_BASE_URL;

// Create Axios instance
const axiosInstance = axios.create({
  baseURL: apiKey,
});

// Function to get access token from localStorage
const getAccessToken = () => {
  return localStorage.getItem("mangalamAdminToken");
};

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve access token before each request
    const token = getAccessToken();

    // Set access token in request headers
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Handle successful responses
    return response;
  },
  (error) => {
    // Handle errors
    handleTokenErrors(error);
    return Promise.reject(error);
  }
);

export const handleTokenErrors = (error) => {
  if (error.response && error.response.data.error === "jwt expired") {
    localStorage.removeItem("mangalamAdminToken"); // Remove token from localStorage
    window.location.href = "/"; // Redirect to the signin page
  }
};

export default axiosInstance;
