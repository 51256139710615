import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../axiosInterceptor'
import { toast } from 'react-toastify'

const AddMileStone = () => {
    const navigator = useNavigate();
    const location = useLocation();
    const editData = location.state
    const [data, setData] = useState({
        position: editData?.position || '',
        title: editData?.title || '',
        description: editData?.description || '',
    });

    const [errors, setErrors] = useState({
        position: '',
        title: '',
        description: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasErrors = false;

        if (!data.position.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                position: 'Position is required'
            }));
            hasErrors = true;
        }
        if (!data.title) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                title: 'Title is required'
            }));
            hasErrors = true;
        }
        if (!data.description) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                description: 'Description is required'
            }));
            hasErrors = true;
        }

        if (hasErrors) {
            return;
        }

        try {
            const response = await axiosInstance.post(`milestone/createMilestone`, data);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/milestone");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };
    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.put(`milestone/updateMilestone/${editData?._id}`, data);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/milestone");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="page-header mt-5 mx-4">
                    <h3 className="page-title">Add Milestone </h3>
                </div>
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="col-md-8  ">
                        <div className="card">
                            <div className="card-body">
                                <form className="pt-3" onSubmit={editData?._id ? handleUpdate : handleSubmit}>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Position
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                className="form-control form-control-sm"
                                                id="position"
                                                name="position"
                                                placeholder="Enter position"
                                                value={data.position}
                                                onChange={handleChange}
                                            />
                                            {errors.position && <p className="text-danger">{errors.position}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="title"
                                                name="title"
                                                placeholder="Enter title"
                                                value={data.title}
                                                onChange={handleChange}
                                            />
                                            {errors.title && <p className="text-danger">{errors.title}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Description
                                        </label>
                                        <div className="col-sm-9">
                                            <textarea
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="description"
                                                name="description"
                                                placeholder="Enter description"
                                                value={data.description}
                                                onChange={handleChange}
                                            />
                                            {errors.description && <p className="text-danger">{errors.description}</p>}
                                        </div>
                                    </div>

                                    <div className='text-center'>
                                        <Link to="/milestone"><button className="btn btn-secondary mr-2">
                                            Back </button></Link>
                                        <button type="submit" className="btn btn-success">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddMileStone;