import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from "./dashboard/Dashboard";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import Sidebar from "./Layout/Sidebar";
import Login from './authentication/Login';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Forgotpassword from './authentication/Forgotpassword';
import Slider from './masterpages/Slider';
import AddSlider from './masterpages/AddSlider';
import Product from './masterpages/Product';
import AddProduct from './masterpages/AddProduct';
import Brand from './masterpages/Brand';
import AddBrand from './masterpages/AddBrand';
import AddCounter from './masterpages/AddCounter';
import Counter from './masterpages/Counter';
import AddMileStone from './masterpages/AddMileStone';
import Milestone from './masterpages/Milestone';
import AddCertificate from './masterpages/AddCertificate';
import Certificate from './masterpages/Certificate';
import Menu from './masterpages/Menu';
import AddMenu from './masterpages/AddMenu';
import Setting from './setting/Setting';
import ResetPassword from './authentication/ResetPassword';
import ChangePasssword from './authentication/ChangePasssword';
import EmailSetting from './setting/EmailSetting';
import Contact from './masterpages/Contact';
import Profile from './masterpages/Profile';
import Page from './masterpages/Page';
import AddPage from './masterpages/AddPage';
import AddContent from './masterpages/AddContent';
import Content from './masterpages/Content';
import Contactview from './masterpages/Contactview';
import SocialMedia from './masterpages/SocialMedia';
import AddSocialMedia from './masterpages/AddSocialMedia';
import Sitemap from './setting/Sitemap';
import EmailTemplate from './setting/EmailTemplate';

function RequireAuth({ children }) {
  let accessToken = localStorage.getItem("mangalamAdminToken");
  if (!accessToken) {
    return <Navigate to="/" />;
  }
  return children;
}

function App() {
  let accessToken = localStorage.getItem("mangalamAdminToken");
  return (
    <Router>
      <Routes>
        {!accessToken ? <Route path="/" element={<Login />} /> : ''}
        <Route path="/forgot-password" element={<Forgotpassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="*"
          element={
            <RequireAuth>
              <Header />
              <div className="container-scroller">
                <div className="container-fluid page-body-wrapper">
                  <Sidebar />
                  <div className="main-panel">
                    <Routes>
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/slider" element={<Slider />} />
                      <Route path="/add-slider" element={<AddSlider />} />
                      <Route path="/product" element={<Product />} />
                      <Route path="/add-product" element={<AddProduct />} />
                      <Route path="/brand" element={<Brand />} />
                      <Route path="/add-brand" element={<AddBrand />} />
                      <Route path="/counter" element={<Counter />} />
                      <Route path="/add-counter" element={<AddCounter />} />
                      <Route path="/milestone" element={<Milestone />} />
                      <Route path="/add-milestone" element={<AddMileStone />} />
                      <Route path="/certificate" element={<Certificate />} />
                      <Route path="/add-certificate" element={<AddCertificate />} />
                      <Route path="/menu" element={<Menu />} />
                      <Route path="/add-menu" element={<AddMenu />} />
                      <Route path="/settings" element={<Setting />} />
                      <Route path="/change-password" element={<ChangePasssword />} />
                      <Route path="/email-settings" element={<EmailSetting />} />
                      <Route path="/contact" element={<Contact />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/page" element={<Page />} />
                      <Route path="/add-page" element={<AddPage />} />
                      <Route path="/content" element={<Content />} />
                      <Route path="/add-content" element={<AddContent />} />
                      <Route path="/contact-view" element={<Contactview />} />
                      <Route path="/social-media" element={<SocialMedia />} />
                      <Route path="/add-social-media" element={<AddSocialMedia />} />
                      <Route path="/email-template" element={<EmailTemplate />} />
                      <Route path="/sitemap" element={<Sitemap />} />
                    </Routes>
                    <Footer />
                  </div>
                </div>
              </div>
            </RequireAuth>
          }
        />
      </Routes>
      <ToastContainer autoClose={1000} />
    </Router>
  );
}

export default App;


