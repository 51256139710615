import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../axiosInterceptor";
import { toast } from "react-toastify";
import { ImageLink } from "../constants";

const Setting = () => {
  const [settingData, setSettingData] = useState([]);

  const [data, setData] = useState({
    websiteMetaTitle: "",
    websiteMetaDescription: "",
    logo: "",
    favicon: "",
    uploadBrochure: "",
    cultureVideo: "",
    headOffice: "",
    contactEmail: "",
    contactNumber: "",
    brandTitle: "",
    brandSubTitle: "",
    officeMapUrl: "",
    cultureVideoUrl: "",
    headerLayout: "",
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`settings/getSettings`);
      const { data } = response.data;
      setSettingData(data);
      setData({ ...data });
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const handleChange = (e) => {
    const { name, files, value } = e.target;
    if (name === "logo" && files.length > 0) {
      const file = files[0];
      if (!file.type.startsWith("image/")) {
        return toast.error("Only image files (JPEG, JPG, PNG) are allowed ");
      }
      setData({ ...data, [name]: file });
    } else if (name === "favicon" && files.length > 0) {
      const file = files[0];
      if (!file.type.startsWith("image/")) {
        return toast.error("Only image files (JPEG, JPG, PNG) are allowed ");
      }
      setData({ ...data, [name]: file });
    }
    else if (name === "uploadBrochure" && files.length > 0) {
      const file = files[0];
      if (file.type !== "application/pdf") {
        return toast.error("Only PDF files are allowed ");
      }
      setData({ ...data, [name]: file });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      if (data.logo instanceof File) {
        formData.append("logo", data.logo);
      }
      if (data.favicon instanceof File) {
        formData.append("favicon", data.favicon);
      }
      formData.append("websiteMetaTitle", data.websiteMetaTitle);
      formData.append("websiteMetaDescription", data.websiteMetaDescription);
      formData.append("headOffice", data.headOffice);
      formData.append("contactEmail", data.contactEmail);
      formData.append("contactNumber", data.contactNumber);
      formData.append("officeMapUrl", data.officeMapUrl);
      formData.append("brandTitle", data.brandTitle);
      formData.append("brandSubTitle", data.brandSubTitle);
      formData.append("cultureVideoUrl", data.cultureVideoUrl);
      formData.append("headerLayout", data.headerLayout);
      if (data.cultureVideo instanceof File) {
        formData.append("cultureVideo", data.cultureVideo);
      }

      if (data.uploadBrochure instanceof File) {
        formData.append("uploadBrochure", data.uploadBrochure);
      }

      const response = await axiosInstance.put(
        `settings/updateSettings`,
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="page-header mt-5 mx-4">
          <h3 className="page-title">Settings </h3>
        </div>
        <div className="col-md-12 grid-margin stretch-card">
          <div className="col-md-8  ">
            <div className="card">
              <div className="card-body">
                <form className="pt-3" onSubmit={handleUpdate}>
                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Website Meta Title
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="websiteMetaTitle"
                        name="websiteMetaTitle"
                        placeholder="Enter website meta title"
                        value={data.websiteMetaTitle}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Website Meta Description
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="websiteMetaDescription"
                        name="websiteMetaDescription"
                        placeholder="Enter website meta description"
                        value={data.websiteMetaDescription}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Logo
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="file"
                        className="form-control form-control-sm"
                        id="logo"
                        name="logo"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Favicon
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="file"
                        className="form-control form-control-sm"
                        id="favicon"
                        name="favicon"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Head Office
                    </label>
                    <div className="col-sm-9">
                      <textarea
                        type="text"
                        className="form-control form-control-sm"
                        id="headOffice"
                        name="headOffice"
                        placeholder="Enter headOffice"
                        value={data.headOffice}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Contact Email
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="contactEmail"
                        name="contactEmail"
                        placeholder="Enter contactEmail"
                        value={data.contactEmail}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Contact Number
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="contactNumber"
                        name="contactNumber"
                        placeholder="Enter contactNumber"
                        value={data.contactNumber}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Office Map Url
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="officeMapUrl"
                        name="officeMapUrl"
                        placeholder="Enter office map url"
                        value={data.officeMapUrl}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Brand Title
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="brandTitle"
                        name="brandTitle"
                        placeholder="Enter brand title"
                        value={data.brandTitle}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Brand Sub Title
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="brandSubTitle"
                        name="brandSubTitle"
                        placeholder="Enter brand sub title"
                        value={data.brandSubTitle}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Header Layout
                    </label>
                    <div className="col-sm-9">
                      <select
                        type="text"
                        className="form-control form-control-sm"
                        id="headerLayout"
                        name="headerLayout"
                        onChange={handleChange}
                        value={data.headerLayout}
                      >
                        <option value="1">Header 1</option>
                        <option value="2">Header 2</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Culture Video
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="file"
                        className="form-control form-control-sm"
                        id="cultureVideo"
                        name="cultureVideo"
                        placeholder="Enter culture video"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Culture Video Url
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="cultureVideoUrl"
                        name="cultureVideoUrl"
                        placeholder="Enter cultureVideoUrl"
                        value={data.cultureVideoUrl}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="exampleInputUsername2"
                      className="col-sm-3 col-form-label"
                    >
                      Upload Brochure
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="file"
                        className="form-control form-control-sm"
                        id="uploadBrochure"
                        name="uploadBrochure"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="text-center">
                    <Link to="/slider">
                      <button className="btn btn-secondary mr-2">Back </button>
                    </Link>
                    <button type="submit" className="btn btn-success">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-4 stretch-card">
            <div className="card">
              <div className="border w-100 h-500 text-center">
                <h6 className="mt-3">Logo Preview</h6>
                {data.logo instanceof File ? (
                  <img
                    src={URL.createObjectURL(data.logo)}
                    alt="logo_preview"
                    className="w-50"
                  />
                ) : (
                  <img
                    src={ImageLink(settingData.logo)}
                    alt="logo_preview"
                    className="w-50"
                  />
                )}
              </div>
              {data.favicon && (
                <div className="border w-100 h-80 text-center ">
                  <h6 className="mt-3">Favicon Preview</h6>
                  {data.favicon instanceof File ? (
                    <img
                      src={URL.createObjectURL(data.favicon)}
                      alt="favicon_preview"
                      className="w-50"
                    />
                  ) : (
                    <img
                      src={ImageLink(settingData.favicon)}
                      alt="favicon_preview"
                      className="w-50"
                    />
                  )}
                </div>
              )}

              {data.uploadBrochure && (
                <div className="border w-100 h-80 text-center mb-4">
                  <h6 className="mt-3">Upload Brochure Preview</h6>
                  {data.uploadBrochure instanceof File ? (
                    <Link
                      to={URL.createObjectURL(data.uploadBrochure)}
                      target="_blank"
                    >
                      <img
                        src="images/icon/pdf.png"
                        alt="pdf_preview"
                        className="ms-3 me-3 mb-3"
                        width={60}
                      />
                    </Link>
                  ) : (
                    <Link
                      to={ImageLink(settingData.uploadBrochure)}
                      target="_blank"
                    >
                      <img
                        src="images/icon/pdf.png"
                        alt="pdf_preview"
                        className="ms-3 me-3 mb-3"
                        width={60}
                      />
                    </Link>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
