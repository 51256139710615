import React from "react";
import { toast } from "react-toastify";
import axiosInstance from "../axiosInterceptor";

const Sitemap = () => {
    const handleClick = async () => {
        try {
            const response = await axiosInstance.get(`/sitemap/createSiteMap`);
            toast.success(response.data.message);
        } catch (error) {
            toast.error(error);
        }
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="page-header mt-5 mx-4">
                    <h3 className="page-title">Sitemap</h3>
                </div>
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-box height-100-p widget-style3 p-4">
                                    <div className="">
                                        <button onClick={handleClick} className="btn btn-inverse-info btn-fw">
                                            Generate Sitemap
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sitemap;
