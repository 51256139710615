import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from '../axiosInterceptor';

const ResetPassword = () => {
  const location = useLocation();
  const navigator = useNavigate();
  const [email, setEmail] = useState(location.state?.email || '');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: '' });
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitAttempted(true);

    let errors = {};

    if (!email.trim()) {
      errors.email = 'Email is required';
    }

    if (!password.trim()) {
      errors.password = 'Password is required';
    }

    if (!confirmPassword.trim()) {
      errors.confirmPassword = 'Confirm Password is required';
    } else if (password !== confirmPassword) {
      errors.confirmPassword = "Passwords don't match";
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    try {
      const response = await axiosInstance.post(`admin/resetPassword`, { email, password });
      if (response.status === 200) {
        toast.success(response.data.message)
        navigator("/")
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again!!');
    }
  }

  return (
    <>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth">
            <div className="row flex-grow">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left p-5">
                  <div className="brand-logo text-center">
                    <img src="../../images/logo.png" alt="Logo_img" />
                  </div>
                  <h4 className='text-center'>Admin Login</h4>
                  <form className="pt-3" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control form-control-sm"
                        id="email"
                        name="email"
                        placeholder="Email"
                        value={email}
                        onChange={handleChange}
                      />
                      {submitAttempted && errors.email && <p className="text-danger">{errors.email}</p>}
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-sm"
                        id="password"
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={handleChange}
                      />
                      {submitAttempted && errors.password && <p className="text-danger">{errors.password}</p>}
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-sm"
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={handleChange}
                      />
                      {submitAttempted && errors.confirmPassword && <p className="text-danger">{errors.confirmPassword}</p>}
                    </div>
                    <div className="mt-3">
                      <button
                        type="submit"
                        className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn text-uppercase"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword
