import React, { useState } from 'react'
import { FaCircle, FaRegCircle } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom'

const Sidebar = () => {
    const location = useLocation().pathname;
    const [selectedItem, setSelectedItem] = useState(null);

    const handleItemClick = (itemName) => {
        setSelectedItem(itemName === selectedItem ? null : itemName);
    };
    return (
        <>
            <nav className={`sidebar sidebar-offcanvas ${location.pathname === "/" || location.pathname === "/forgot-password" ? "d-none" : ""}`} id="sidebar">
                <ul className="nav">

                    <li className="nav-item nav-profile">
                        <Link className="nav-link" to="/dashboard">
                            <span className="menu-title">Dashboard</span>
                            <i className="icon-screen-desktop menu-icon" />
                        </Link>
                    </li>

                    <li className="nav-item">
                        <Link className="nav-link" to="/contact">
                            <span className="menu-title">Contact Details</span>
                            <i className="icon-user menu-icon"></i>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/settings">
                            <span className="menu-title">Settings</span>
                            <i className="icon-settings menu-icon" />

                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/email-settings">
                            <span className="menu-title">Email Settings</span>
                            <i className="icon-globe menu-icon" />

                        </Link>
                    </li>
                    <li className="nav-item">
                        <a
                            className="nav-link"
                            data-toggle="collapse"
                            href="#ui-basic"
                            aria-expanded="false"
                            aria-controls="ui-basic"
                        >
                            <span className="menu-title">Master Tables</span>
                            <i className="icon-layers menu-icon" />
                        </a>
                        <div className="collapse" id="ui-basic">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/menu" onClick={() => handleItemClick('Menu')}>
                                        {selectedItem === 'Menu' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />} Menu
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/slider" onClick={() => handleItemClick('Slider')}>
                                        {selectedItem === 'Slider' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}    Slider
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/product" onClick={() => handleItemClick('Product')}>
                                        {selectedItem === 'Product' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}Product
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/brand" onClick={() => handleItemClick('Brand')}>
                                        {selectedItem === 'Brand' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}  Brand
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/counter" onClick={() => handleItemClick('Counter')}>
                                        {selectedItem === 'Counter' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />} Counter
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/milestone" onClick={() => handleItemClick('Milestone')}>
                                        {selectedItem === 'Milestone' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />} Milestone
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/certificate" onClick={() => handleItemClick('Certificate')}>
                                        {selectedItem === 'Certificate' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}Certificate
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/page" onClick={() => handleItemClick('Page')}>
                                        {selectedItem === 'Page' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}Page
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/content" onClick={() => handleItemClick('Content')}>
                                        {selectedItem === 'Content' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}Content
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/social-media" onClick={() => handleItemClick('Social Media')}>
                                        {selectedItem === 'Social Media' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />} Social Media
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/email-template" onClick={() => handleItemClick('Email Template')}>
                                        {selectedItem === 'Email Template' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}Email Template
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/sitemap" onClick={() => handleItemClick('Sitemap')}>
                                        {selectedItem === 'Sitemap' ? <FaCircle className='text-success mx-3' /> : <FaRegCircle className='mx-3' />}Sitemap
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </nav>


        </>
    )
}

export default Sidebar