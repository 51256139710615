import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axiosInstance from '../axiosInterceptor'
import { toast } from 'react-toastify'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const Milestone = () => {
    const [loading, setLoading] = useState(true);
    const [milestoneData, setMilestoneData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const navigator = useNavigate();
    const perPage = 10;

    const fetchData = async (page = 1) => {
        try {
            const response = await axiosInstance.post(
                `milestone/getAllMilestone`,
                {
                    perPage: perPage,
                    pageNo: page,
                }
            );
            const { data, totalPages } = response.data;
            setMilestoneData(data);
            setTotalPages(totalPages);
            setCurrentPage(page);
            setLoading(false)
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handlePageClick = (page) => {
        if (page >= 1 && page <= totalPages) {
            fetchData(page);
        }
    };

    const handleDelete = async (id) => {
        setDeleteItemId(id);
        setModalOpen(true);
    };

    const confirmDelete = async () => {
        try {
            const response = await axiosInstance.delete(`milestone/deleteMilestone/${deleteItemId}`);
            if (response.status === 200) {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        } finally {
            setDeleteItemId(null);
            setModalOpen(false);
        }
    };
    if (loading) {
        return <p>Loading.....</p>
    }
    return (
        <>
            <div className="content-wrapper">
                <div className="page-header mt-5">
                    <h3 className="page-title">Milestone</h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/add-milestone">
                                    <button type="button" className="btn btn-inverse-info btn-fw"><i className="icon-plus mx-2 mt-2"></i>Add Milestone</button></Link>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive border rounded p-1">
                                    <table className="table text-center">
                                        <thead>
                                            <tr>
                                                <th className="font-weight-bold">Position</th>
                                                <th className="font-weight-bold">Title</th>
                                                <th className="font-weight-bold">Description</th>
                                                <th className="font-weight-bold">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                milestoneData.length > 0 ? (
                                                    milestoneData.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{item.position}</td>
                                                                <td>{item.title}</td>
                                                                <td>{item.description.slice(0,25)}...</td>
                                                                <td>
                                                                    <div className="template-demo">
                                                                        <button type="button" className="btn btn-outline-primary btn-icon-text btn-sm w-40" onClick={() => { navigator('/add-milestone', { state: item }) }}><i className="icon-pencil btn-icon-append mr-1"></i> Edit
                                                                        </button>
                                                                        <button type="button" className="btn btn-outline-danger btn-icon-text btn-sm w-35" onClick={() => handleDelete(item._id)}><i className="icon-trash btn-icon-append"></i> Delete
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })) : (
                                                    <tr>
                                                        <td colSpan="6"><h5>No data found.</h5></td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="d-flex mt-4 flex-wrap">
                                    <p className="text-muted">Showing {((currentPage - 1) * perPage) + 1} to {Math.min((currentPage * perPage), milestoneData.length)} of {totalPages * perPage} entries</p>
                                    <nav className="ml-auto">
                                        <ul className="pagination separated pagination-info">
                                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => handlePageClick(currentPage - 1)}>
                                                    <i className="icon-arrow-left" />
                                                </button>
                                            </li>
                                            {Array.from({ length: totalPages }, (_, i) => (
                                                <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                                                    <button className="page-link" onClick={() => handlePageClick(i + 1)}>
                                                        {i + 1}
                                                    </button>
                                                </li>
                                            ))}
                                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                <button className="page-link" onClick={() => handlePageClick(currentPage + 1)}>
                                                    <i className="icon-arrow-right" />
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} >
                <ModalHeader >Confirm Delete
                </ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this milestone?
                </ModalBody>
                <ModalFooter>
                    <div className="">
                        <Button
                            type="cancel"
                            className="btn btn-success btn-fw"
                            onClick={() => setModalOpen(!modalOpen)}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div className="">
                        <Button
                            type="button" className="btn btn-danger btn-fw"
                            onClick={confirmDelete}
                        >
                            Delete
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default Milestone;